export const RentalMandatePacks = Object.freeze({
  1: "common.comprehensiveManagementWithMarketing",
  2: "common.commercialization",
  3: "common.incidentManagementWithMarketing",
  4: "common.integralManagement",
  5: "common.managementOfIncidents",
  6: "common.individualized",
  7: "common.safePackageWithMarketing",
  8: "common.safePackage",
});
